export default {
  wrapper: 'bg-white',
  pagination: 'py-4 pb-1.5',

  headerHead: '',
  headerRow: '',
  headerColumn: 'bg-white px-4 border-b-2 border-b-gray-border text-left', // text-left is necessary to override browser default "text-align: -internal-center" which messes up tooltip placement
  headerColumnTitle: 'font-semibold text-text-primary text-sm leading-normal',
  headerColumnSortable: '!pr-8',
  firstColumn: '',
  lastColumn: '',

  body: 'bg-white',

  row: 'flex flex-nowrap flex-1 group hover:bg-[#F6FAFE] hover:border-[#F6FAFE]',
  rowSelected: '!bg-[#E3F0FC] hover:!border-[#E3F0FC]',

  cell: 'flex items-center justify-start p-1.25',
  cellEditable: 'hover:rounded-lg hover:bg-white hover:outline hover:outline-1 hover:outline-gray-400 focus:bg-white',

  rowCell: ' border-gray-400 bg-gray-400 text-text-primary font-regular text-sm h-full rounded-lg',
  frozenCell: 'sticky z-50 font-regular group-hover:bg-[#F6FAFE] group-hover:border-[#F6FAFE]',

  footer: 'bg-gray-200',
  footerHead: 'bg-gray-200',
  footerRow: '',
  footerColumn: 'bg-gray-200 border-t-2 border-gray-border text-sm py-1.5 px-4 h-10',
  footerColumnTitle: 'font-medium text-gray-600 text-sm uppercase',

  checkboxCell: 'sticky left-0 z-50 bg-white group-hover:bg-[#F6FAFE] group-hover:border-[#F6FAFE] border border-transparent flex items-center justify-center px-2 py-1.25',
  checkboxCellRowSelected: '!bg-[#E3F0FC] group-hover:border-[#E3F0FC]',
  checkbox: 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-2 border-gray-400 rounded cursor-pointer disabled:cursor-not-allowed disabled:opacity-20',

  // editableCell: 'hover:!border hover:!border-gray-400 hover:!bg-white focus:!bg-white',

  inputCellValid: 'text-text-primary',
  inputCellNotValid: 'text-red-500',
  cellValid: 'text-text-primary',
  cellInfo: 'rounded-lg !bg-blue-100 hover:!outline-blue-400',
  cellNotValid: 'rounded-lg !bg-red-100 hover:!outline-red-400',
  cellWarning: 'rounded-lg !bg-amber-100 hover:!outline-amber-400',
  cellLocked: '!text-slate-400',

  isEditable: {
    cellValid: '!text-text-primary'
  }
}
