import { RouteRecordRaw } from 'vue-router'

import { rolesGroup } from '@/static/roles'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

export function websites (): RouteRecordRaw[] {
  const context = ContextType.PUBLISHERS
  return [
    {
      path: 'websites',
      name: `${context}.websites.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.websiteApp', 100),
        feature: 'websites',
        roles: rolesGroup.user
      },
      children: [
        {
          path: ':websiteId/edit',
          name: `${context}.websites.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('websites.edit', { name: ':websiteName' }),
            feature: 'websites',
            pattern: ':websiteName'
          },
          props: route => ({ websiteId: route.params.websiteId })
        },
        {
          path: 'create',
          name: `${context}.websites.create`,
          component: () => import('./views/Create.vue'),
          meta: {
            title: i18n.global.t('websites.create'),
            feature: 'websites',
            roles: rolesGroup.operation
          },
          props: route => ({ publisherId: route.params.publisherId })
        },
        {
          path: 'bulk',
          name: `${context}.websites.bulk`,
          component: () => import('./views/Bulk.vue'),
          meta: {
            // layout: 'base',
            title: i18n.global.t('websites.bulk'),
            feature: 'websites',
            roles: rolesGroup.operation
          }
        },
        {
          path: 'bulk/apps',
          name: `${context}.websites.bulk.apps`,
          component: () => import('./views/BulkApps.vue'),
          meta: {
            // layout: 'base',
            title: i18n.global.t('websites.bulkApp'),
            feature: 'websites',
            roles: rolesGroup.adagio,
            requireService: true
          }
        }
      ]
    }
  ]
}

export function websitesGroups (): RouteRecordRaw[] {
  const context = ContextType.GROUPS
  return [
    {
      path: 'websites',
      name: `${context}.websites.index`,
      component: () => import('./views/Index.vue'),
      meta: {
        title: i18n.global.t('labels.websiteApp', 100),
        requireService: true
      },
      children: [
        {
          path: ':websiteId/edit',
          name: `${context}.websites.edit`,
          component: () => import('./views/Edit.vue'),
          meta: {
            title: i18n.global.t('websites.edit', { name: ':websiteName' }),
            feature: 'websites',
            pattern: ':websiteName'
          },
          props: route => ({ websiteId: route.params.websiteId })
        }
      ]
    }
  ]
}
