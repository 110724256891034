import { Filter } from '@/plugins/filters'

import { ClientFilters } from '../filters/filters'
import { CondFilter, CondOperator, newDateForFiltering } from '../filters/operators'

import { Row } from './datatable.d'

export const applyFiltersOnRows = (rows: Row[], filters: ClientFilters): Row[] => {
  if (filters.filters.value.length === 0) {
    return rows
  }

  let mergeResult : (previousRows: Row[], currentRows: Row[]) => Row[]
  let filteredRows: Row[]
  if (filters.filtersCond.value === CondFilter.OR) {
    // If OR all filters results are merge in the same result array
    mergeResult = (previousRows: Row[], currentRows: Row[]): Row[] => {
      return Array.from(new Set([...previousRows, ...currentRows]))
    }
    filteredRows = []
  } else {
    // If AND all filters are applied on the previous filters results
    mergeResult = (previousRows: Row[], currentRows: Row[]): Row[] => {
      return currentRows
    }
    filteredRows = rows
  }

  for (const filter of filters.filters.value) {
    try {
      if (filters.filtersCond.value === CondFilter.OR) {
        filteredRows = mergeResult(filteredRows, applyFilterOnRows(rows, filter.fieldKey, filter))
      } else {
        filteredRows = mergeResult(filteredRows, applyFilterOnRows(filteredRows, filter.fieldKey, filter))
      }
    } catch (e) {
      console.error(e)
    }
  }
  return filteredRows
}

const applyFilterOnRows = (rows: Row[], field: string, filter: Filter): Row[] => {
  switch (filter.operator) {
    case CondOperator.ARRAY_ANY:
    case CondOperator.ARRAY_IN:
    case CondOperator.ARRAY_NOTIN:
    case CondOperator.IN:
    case CondOperator.NOT_IN:
      return filterRowsByArray(rows, field, filter)
    case CondOperator.EQUALS:
    case CondOperator.NOT_EQUALS:
    case CondOperator.GREATER_THAN:
    case CondOperator.GREATER_THAN_EQUALS:
    case CondOperator.LOWER_THAN:
    case CondOperator.LOWER_THAN_EQUALS:
    case CondOperator.CONTAINS:
    case CondOperator.EXCLUDES:
    case CondOperator.IS_NULL:
    case CondOperator.NOT_NULL:
    case CondOperator.IS_TRUE:
    case CondOperator.IS_FALSE:
    case CondOperator.DATE_EQUALS:
    case CondOperator.DATE_NOT_EQUALS:
    case CondOperator.DATE_GREATER_THAN:
    case CondOperator.DATE_GREATER_THAN_EQUALS:
    case CondOperator.DATE_LOWER_THAN:
    case CondOperator.DATE_LOWER_THAN_EQUALS:
      return filterRowsByValue(rows, field, filter)
    case CondOperator.BETWEEN:
    case CondOperator.DATE_BETWEEN:
      return filterRowsByRange(rows, field, filter)
    default:
      return rows
  }
}

const filterRowsByArray = (rows: Row[], field: string, filter: Filter):Row[] => {
  if (!Array.isArray(filter.value)) {
    throw new Error('expecting filter value to be an array')
  }

  return rows.filter((row: Row) => {
    switch (filter.operator) {
      case CondOperator.ARRAY_ANY:
        return filter.value.some((el: string) => row.data[field].includes(el))
      case CondOperator.ARRAY_IN:
        return filter.value.every((el: string) => row.data[field].includes(el))
      case CondOperator.IN:
        return filter.value.includes(row.data[field])
      case CondOperator.ARRAY_NOTIN:
        return !filter.value.some((el: string) => row.data[field].includes(el))
      case CondOperator.NOT_IN:
        return !filter.value.includes(row.data[field])
      default:
        return false
    }
  })
}

const filterRowsByValue = (rows: Row[], field: string, filter: Filter):Row[] => {
  if (Array.isArray(filter.value)) {
    throw new Error('expecting filter value to be a single value')
  }

  return rows.filter((row: Row) => {
    switch (filter.operator) {
      case CondOperator.EQUALS:
        // avoid null and undefined to be stringified as "null" and "undefined"
        return String(row.data[field] ?? '') === filter.value
      case CondOperator.NOT_EQUALS:
        return String(row.data[field] ?? '') !== filter.value
      case CondOperator.GREATER_THAN:
        return row.data[field] > filter.value
      case CondOperator.GREATER_THAN_EQUALS:
        return row.data[field] >= filter.value
      case CondOperator.LOWER_THAN:
        return row.data[field] < filter.value
      case CondOperator.LOWER_THAN_EQUALS:
        return row.data[field] <= filter.value
      case CondOperator.CONTAINS:
        // only string and array can "includes" a value
        if (typeof row.data[field] === 'string' || Array.isArray(row.data[field])) {
          return row.data[field].includes(filter.value)
        }
        return false
      case CondOperator.EXCLUDES:
        if (typeof row.data[field] === 'string' || Array.isArray(row.data[field])) {
          return !row.data[field].includes(filter.value)
        }
        return false
      case CondOperator.IS_NULL:
        return row.data[field] === null
      case CondOperator.NOT_NULL:
        return row.data[field] !== null
      case CondOperator.IS_TRUE:
        return row.data[field] === true
      case CondOperator.IS_FALSE:
        return row.data[field] === false
      case CondOperator.DATE_EQUALS:
        return newDateForFiltering(row.data[field]).getTime() === filter.value.getTime()
      case CondOperator.DATE_NOT_EQUALS:
        return newDateForFiltering(row.data[field]).getTime() !== filter.value.getTime()
      case CondOperator.DATE_GREATER_THAN:
        return newDateForFiltering(row.data[field]) > filter.value
      case CondOperator.DATE_GREATER_THAN_EQUALS:
        return newDateForFiltering(row.data[field]) >= filter.value
      case CondOperator.DATE_LOWER_THAN:
        return newDateForFiltering(row.data[field]) < filter.value
      case CondOperator.DATE_LOWER_THAN_EQUALS:
        return newDateForFiltering(row.data[field]) <= filter.value
      default:
        return false
    }
  })
}

const filterRowsByRange = (rows: Row[], field: string, filter: Filter):Row[] => {
  if (
    !(Array.isArray(filter.value) && filter.value.length === 2) &&
      !(typeof filter.value === 'object' && Object.keys(filter.value).length === 2)
  ) {
    throw new Error('expecting filter value to be an array or an object of 2 elements')
  }

  switch (filter.operator) {
    case CondOperator.BETWEEN:
      return rows.filter((row: Row) => {
        return row.data[field] >= filter.value[0] && row.data[field] <= filter.value[1]
      })
    case CondOperator.DATE_BETWEEN:
      return rows.filter((row: Row) => {
        const date = newDateForFiltering(row.data[field])
        return date >= filter.value.start && date <= filter.value.end
      })
    default:
      throw new Error('unexpected operator')
  }
}
