import countries from '@/static/countries.json'

const countriesMap = new Map<string, string>(
  countries.map(c => [c.id.toLocaleLowerCase(), c.name])
)

/**
* getCountryName returns the name of a country based on its ISO 3166-1 alpha-3 code.
* @param countryISO - The ISO 3166-1 alpha-3 code of the country.
*/
export function getCountryName (countryISO: string): string {
  return countriesMap.get(countryISO.toLocaleLowerCase()) || countryISO
}
