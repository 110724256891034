import { ClientFilters } from '../filters/filters'

import { Row } from './datatable.d'

export const applySearchOnRows = (rows: Row[], filters: ClientFilters): Row[] => {
  if (!filters.search) {
    return rows
  }

  return rows.filter((row: Row) => {
    for (const field of Object.keys(row.data)) {
      if (filters.isSearch(field, row.data[field])) {
        return true
      }
    }
    return false
  })
}
