import { Component, InputHTMLAttributes } from 'vue'

import { FormInputHints, FormInputMode } from '@/components/Form'
import FormComponent from '@/components/Form/FormInput.vue'

import { DefaultProps, FormRenderer } from './'

interface Props extends DefaultProps {
  hints?: FormInputHints
  mode?: FormInputMode
  focusOnMount?: boolean
  textLimitation?: number
  nullIfEmpty?: boolean
  undefinedIfEmpty?: boolean
  attr?: InputHTMLAttributes
  events?: Record<string, Function>
  onChange?: (e: any) => void
}

export class FormInput implements FormRenderer<Props> {
  defaultProps: Props

  constructor (props: Props) {
    this.defaultProps = props
  }

  get wrapperClass (): string {
    return this.defaultProps.wrapperClass || ''
  }

  props (): Props {
    const attrs = { ...this.defaultProps.attr }
    const cleanProps = { ...this.defaultProps }
    delete cleanProps.attr
    delete cleanProps.events

    return { ...cleanProps, ...attrs }
  }

  events (): Record<string, Function> {
    return this.defaultProps.events || {}
  }

  component (): Component {
    return FormComponent
  }
}
