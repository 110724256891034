<template>
  <EnumComponent
    :value="translatedValue"
    :bg-class="bgClass"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { getCountryName } from '@/utils/country'

import EnumComponent from './EnumComponent.vue'

export default defineComponent({
  components: { EnumComponent },
  props: {
    value: {
      type: String,
      required: true
    },
    bgClass: {
      type: String,
      default: 'bg-primary-100'
    }
  },
  setup (props) {
    const translatedValue = computed(() => getCountryName(props.value))
    return {
      translatedValue
    }
  }
})
</script>
