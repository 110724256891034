import { AxiosResponse } from 'axios'

import { Paginate } from '@/types/paginate'

import { CreateView, UpdateView, View } from '@/models/view'

import api from '@/api'

export async function fetchViews (queryParams?: Object, withContext = false): Promise<AxiosResponse<Paginate<View[]>>> {
  return api.get<Paginate<View[]>>('/views', { requireContext: withContext, params: queryParams })
}

export async function createView (data: CreateView): Promise<AxiosResponse<View>> {
  return api.post<View>('/views', data)
}

export async function updateView (id: number, data: UpdateView): Promise<AxiosResponse<View>> {
  return api.patch<View>(`/views/${id}`, data)
}

export async function deleteView (id: number): Promise<AxiosResponse<void>> {
  return api.delete(`/views/${id}`)
}
