<template>
  <div class="max-w-full md:max-w-7xl">
    <form
      method="POST"
      class="flex flex-col divide-y divide-gray-200"
      @submit.prevent="onSubmit"
    >
      <div class="">
        <div class="px-4 divide-y divide-gray-200 sm:px-6">
          <div class="pt-6 pb-5 space-y-6">
            <FormFields
              v-model="form"
              :fields="fields"
              :errors="errors"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end flex-shrink-0 px-4 py-4 space-x-4 border-t sm:px-6 sm:py-6">
        <AppButton
          type="submit"
          :hide-if-missing-permission="false"
          :is-loading="state.isSubmitting"
          :is-disabled="state.isSubmitting"
          @submit="onSubmit"
        >
          {{ t('actions.download') }}
        </AppButton>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'

import { cubeSchemas, PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA, PUBLISHER_REPORTS_SCHEMA_V1 } from '@/static/reports'
import timezones from '@/static/timezones'

import { FormType } from '@/types/form'

import { DataDefinition } from '@/plugins/dashboard/definition'
import { CUBE_ENDPOINT, CubeSource } from '@/plugins/dashboard/source'
import { FieldTemplate, useForm } from '@/plugins/form'
import { FormDateRange, FormMultiselect, FormSection } from '@/plugins/form/renderer'

import { useContextStore } from '@/store/context.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { transformToExport } from '@/models/export'
import { createInstantReportForm, InstantReportForm, instantReportFormRules } from '@/models/report'

import { createExport } from '@/services/exports'
import { fetchSeatsForPublisher } from '@/services/seats'

import api from '@/api'
import AppButton from '@/components/Buttons/AppButton.vue'
import FormFields from '@/components/FormBuilder/FormFields.vue'

export default defineComponent({
  components: {
    FormFields,
    AppButton
  },
  setup () {
    const { t } = useI18n()

    const contextStore = useContextStore()
    const notificationsStore = useNotificationsStore()

    const reportForm = ref<InstantReportForm>(createInstantReportForm())

    const tables = ref({} as Record<string, DataDefinition>)
    const sources = ref<Array<{id: any, name: string}>>([])
    const dimensions = ref<Array<{id: any, name: string}>>([])
    const metrics = ref<Array<{id: any, name: string}>>([])

    const currentPublisher = computed(() => contextStore.contextSelectedPublisher)

    const fetchCubeSources = () => {
      const source = new CubeSource('SourceForm', '', api.contextURL(CUBE_ENDPOINT, `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1`), '')

      const schemas = cubeSchemas()

      source.getExistingTables()
        .then(t => {
          tables.value = t
          sources.value = Object.keys(schemas[contextStore.contextType]).filter(tName => {
            return Object.prototype.hasOwnProperty.call(t, tName)
          }).map(tName => {
            return { id: tName, name: schemas[contextStore.contextType][tName]?.title }
          })

          computeSources()
        })
        .catch((e: any) => {
          notificationsStore.add({
            message: t('messages.fetchErrorWithMsg', [e.message]),
            type: 'error'
          })
        })
    }

    // Lifecycle
    onMounted(async () => {
      if (currentPublisher.value) {
        useRequest(fetchSeatsForPublisher, {
          manual: false,
          defaultParams: [currentPublisher.value?.id || 0],
          onSuccess: (response) => {
            if (response?.data) {
              fetchCubeSources()
            }
          }
        })
      } else {
        fetchCubeSources()
      }
    })

    const computeSources = () => {
      if (reportForm.value.source) {
        const currentSource = sources.value.find(s => s.id === reportForm.value.source)
        if (currentSource) {
          updateDimMetrics(currentSource.id)
        }
      }
    }

    watch(
      () => reportForm.value.source,
      () => {
        nextTick(() => {
          computeSources()
        })
      }
    )

    const updateDimMetrics = (sourceId: string) => {
      const schemas = cubeSchemas()

      dimensions.value = tables.value[sourceId].dimensions.filter(v => {
        return schemas[contextStore.contextType][sourceId] && !schemas[contextStore.contextType][sourceId].blacklisted.dimensions.includes(v.name)
      }).map(v => {
        const option = { id: v.name, name: v.name }

        if ([PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA].includes(sourceId) && v.name === 'windowStart') {
          // Map `windowStart` field to `day` dimension to keep all exports consistent.
          // In Cube schema, we kept `wiwndowStart` to avoid to many custom change when we request a report from the api.
          // The code below is for a display purpose only.
          option.name = 'day'
        }

        if (sourceId === PUBLISHER_REPORTS_SCHEMA_V1) {
          const deprecated = schemas[contextStore.contextType][sourceId].deprecated.dimensions

          if (deprecated.includes(v.name)) {
            option.name = `${option.name} (deprecated)`
          }
        }

        return option
      })

      // reorder dimensions based on the schema order definition
      const schema = schemas[contextStore.contextType][sourceId]
      dimensions.value = _.orderBy(dimensions.value, (dimension: {id: any, name: string}) => _.indexOf(schema.order?.dimensions, dimension.id))

      metrics.value = Object.keys(tables.value[sourceId].metrics).filter(v => {
        return schemas[contextStore.contextType][sourceId] && !schemas[contextStore.contextType][sourceId].blacklisted.metrics.includes(v)
      }).map(v => { return { id: v, name: v } })
    }

    const isSourceSeatKPIs = computed(() => reportForm.value.source === 'PublishersDailySeatKpisEnriched')

    watch(
      () => reportForm.value.dimensions,
      () => {
        if (isSourceSeatKPIs.value) {
          const requiredStrings = ['seatId', 'seatName']
          requiredStrings.forEach((item) => {
            if (!reportForm.value.dimensions.includes(item)) {
              reportForm.value.dimensions.push(item)
            }
          })
        }
      }
    )

    const { form, onSubmit, reset, state, errors, validation } = useForm<InstantReportForm>({
      defaultForm: computed(() => reportForm.value),
      formType: FormType.CREATE,
      submitHandler: (form) => {
        return createExport(transformToExport(form))
      },
      rules: instantReportFormRules,
      onSuccess: (response) => {
        if (response && response.data) {
          const a = window.document.createElement('a')
          a.href = response.data.url
          a.target = '_blank'

          const evt = new MouseEvent('click', {
            view: document.defaultView,
            bubbles: true,
            cancelable: false
          })

          a.dispatchEvent(evt)

          notificationsStore.add({
            title: t('reports.download'),
            message: t('reports.downloadInProgress'),
            type: 'success'
          })
        }
      },
      onError: (e) => {
        console.error(e)
        notificationsStore.add({
          title: t('labels.error'),
          message: t('reports.createError', [e.message]),
          type: 'error'
        })
      }
    })

    reportForm.value = form

    const hasSource = computed(() => !!(sources.value.length))

    const onSourceUpdated = () => {
      reportForm.value.dimensions = []
      reportForm.value.metrics = []
      validation.value.$reset() // avoid to get errors displayed as the both properties cannot be empty
    }

    watch(
      () => reportForm.value.source,
      onSourceUpdated
    )

    const fields = computed<Array<FieldTemplate<unknown>>>(() => [
      {
        renderer: new FormSection({ title: t('labels.general'), mode: 'base', wrapperClass: 'p-5 rounded-md bg-white' }),
        isSlotComponent: true,
        fields: [
          {
            key: 'source',
            renderer: new FormMultiselect({
              name: 'source',
              label: t('labels.source'),
              attr: {
                mode: 'single',
                'close-on-select': true,
                canClear: false,
                required: true
              },
              isRequired: true,
              options: sources.value
            })
          },
          {
            key: 'dimensions',
            renderer: new FormMultiselect({
              name: 'dimensions',
              label: t('labels.dimension', 2),
              attr: {
                disabled: !hasSource.value
              },
              mode: 'tags',
              isRequired: true,
              options: dimensions.value,
              mandatoryValues: isSourceSeatKPIs.value ? ['seatId', 'seatName'] : [],
              hints: {
                textBottom: t('reports.dimensionsHelpText'),
                tooltip: isSourceSeatKPIs.value ? t('reports.instantReport.seatKPIsRequiredField') : ''
              }
            })
          },
          {
            key: 'metrics',
            renderer: new FormMultiselect({
              name: 'metrics',
              label: t('labels.metric', 2),
              attr: { disabled: !hasSource.value },
              mode: 'tags',
              isRequired: true,
              options: metrics.value
            })
          },
          {
            key: 'dateRange',
            renderer: new FormDateRange({
              name: 'dateRange',
              label: t('labels.dateRange'),
              withLeftIcon: false,
              isRequired: true
            })
          },
          {
            key: 'timezone',
            // hide TZ selection for daily aggregated sources to prevent misleading users
            condition: () => reportForm.value.source !== 'PublishersDailySeatKpisEnriched',
            renderer: new FormMultiselect({
              name: 'timezone',
              label: t('labels.timezone'),
              attr: { mode: 'single', searchable: true, closeOnSelect: true, required: true },
              isRequired: true,
              options: timezones
            })
          }
        ]
      }
    ])

    return {
      t,
      form,
      state,
      errors,
      validation,
      reset,
      onSubmit,
      fields
    }
  }
})
</script>
