import { NaturallyOrderedValue, OrderedValueSelector } from 'crossfilter2'

import { METRIC_FORMATTERS, MetricFormatter } from '../metricFormat'

import { Granularity, SerializedDimension, TIME_GRANULARITIES, TimeGranularity } from '.'

/**
 * A dimension is a descriptive attribute or characteristic on data that is used for grouping (such as "site", "device",
 * "placement", etc).
 * The dimension name is not necessarily equals to the name of the field in the record. (e.g.: a dimension can be called
 * "datetime_by_day" and use the "datetime" field).
 *
 * The selector takes a record as input, and returns the value of the dimension. The value can be altered, for example in order
 * to create aggregates. (e.g.: a "datetime_by_day" dimension will take a date field and remove the time part to only keep the
 * day, month and year so records are aggregated by day)
 *
 * For composite dimensions (e.g.: impressions per site per day: "site" and "datetime_by_day"), see CompositeDimension.
 */
export interface Dimension {
  name: string
  column: string
  enrichment: boolean
  selector: OrderedValueSelector<Record<string, any>, any>
  serialize(): SerializedDimension
}

abstract class BaseDimension {
  name: string
  column: string
  enrichment: boolean

  constructor (name: string, column?: string, enrichment: boolean = false) {
    this.name = name
    this.column = column || name
    this.enrichment = enrichment || false
  }
}

export interface RollupDimension<TGranularity extends NaturallyOrderedValue> extends Dimension {
  granularity: Granularity<TGranularity>
}

export class GenericDimension extends BaseDimension implements Dimension {
  formatterName?: string
  formatter?: MetricFormatter

  constructor (name: string, column?: string, enrichment: boolean = false, formatter?: string) {
    super(name, column, enrichment)
    this.formatterName = formatter
    this.formatter = METRIC_FORMATTERS[formatter ?? '']
  }

  selector (record: Record<string, any>): any {
    return record[this.column]
  }

  serialize (): SerializedDimension {
    return {
      name: this.name,
      constructor: 'GenericDimension', // Hardcode the name so even minimized code still works
      formatter: this.formatterName,
      params: {
        column: this.column,
        enrichment: this.enrichment
      }
    }
  }

  static deserialize (d: SerializedDimension): Dimension {
    if (d.name === undefined || typeof d.name !== 'string') {
      throw new Error('Invalid SerializedDimension (GenericDimension): missing name')
    }
    if (d.params === undefined || d.params.column === undefined || typeof d.params.column !== 'string') {
      throw new Error('Invalid SerializedDimension (GenericDimension): missing parameters')
    }
    return new GenericDimension(d.name, d.params.column, d.params.enrichment, d.formatter)
  }
}

export class DateDimension extends BaseDimension implements Dimension {
  selector (record: Record<string, any>): Date {
    if (record[this.column] === undefined) {
      return new Date(0)
    }
    return new Date(record[this.column] + 'Z') // Timezone UTC
  }

  serialize (): SerializedDimension {
    return {
      name: this.name,
      constructor: 'DateDimension',
      params: {
        column: this.column,
        enrichment: this.enrichment
      }
    }
  }

  static deserialize (d: SerializedDimension): DateDimension {
    if (d.name === undefined || typeof d.name !== 'string') {
      throw new Error('Invalid SerializedDimension (DateDimension): missing name')
    }
    if (d.params === undefined || d.params.column === undefined || typeof d.params.column !== 'string') {
      throw new Error('Invalid SerializedDimension (DateDimension): missing parameters')
    }
    return new DateDimension(d.name, d.params.column, d.params.enrichment)
  }
}

/**
 * Datetime dimension using date-fns to process the rollup size (period parameter).
 * Dates are handled as UTC.
 */
export class DateRollupDimension extends BaseDimension implements RollupDimension<Date> {
  granularity: TimeGranularity

  constructor (name: string, granularity: TimeGranularity, column: string = name, enrichment: boolean = false) {
    super(name, column, enrichment)
    this.granularity = granularity
  }

  selector (record: Record<string, any>): Date {
    return this.granularity.rollup(new Date(record[this.column] + 'Z'))
  }

  serialize (): SerializedDimension {
    return {
      name: this.name,
      params: {
        granularity: Object.entries(TIME_GRANULARITIES).find(v => v[1] === this.granularity)![0],
        column: this.column,
        enrichment: this.enrichment
      },
      constructor: 'DateRollupDimension'
    }
  }

  static deserialize (d: SerializedDimension): DateRollupDimension {
    if (d.name === undefined || typeof d.name !== 'string') {
      throw new Error('Invalid SerializedDimension (DateRollupDimension): missing name')
    }
    if (d.params === undefined || d.params.granularity === undefined || d.params.column === undefined || typeof d.params.column !== 'string') {
      throw new Error('Invalid SerializedDimension (DateRollupDimension): missing parameters')
    }
    const granularity = TIME_GRANULARITIES[d.params.granularity]
    if (granularity === undefined) {
      throw new Error(`Invalid SerializedDimension (DateRollupDimension): granularity "${d.params.granularity}" doesn't exist`)
    }
    return new DateRollupDimension(d.name, granularity, d.params.column, d.params.enrichment)
  }
}

/**
 * Group dimension for comparative scenarios
 */
export class ComparisonGroupDimension extends BaseDimension implements Dimension {
  selector (record: Record<string, any>): any {
    return record[this.column]
  }

  serialize (): SerializedDimension {
    return {
      name: this.name,
      constructor: 'ComparisonGroupDimension', // Hardcode the name so even minimized code still works
      params: {
        column: this.column,
        enrichment: this.enrichment
      }
    }
  }

  static deserialize (d: SerializedDimension): Dimension {
    if (d.name === undefined || typeof d.name !== 'string') {
      throw new Error('Invalid SerializedDimension (ComparisonGroupDimension): missing name')
    }
    if (d.params === undefined || d.params.column === undefined || typeof d.params.column !== 'string') {
      throw new Error('Invalid SerializedDimension (ComparisonGroupDimension): missing parameters')
    }
    return new ComparisonGroupDimension(d.name, d.params.column, d.params.enrichment)
  }
}
