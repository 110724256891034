import Joi from 'joi'

import { FieldType } from '@/plugins/datatable/datatable.d'
import i18n from '@/plugins/i18n'

import { useJoi } from '@/utils/useJoi'

export interface ValueType {
  disable?: boolean
  componentType?: 'multiselect' | 'multivalues' | 'input' | 'date'
  fieldType?: 'number' | 'text' | 'date' | 'daterange' | 'any'
}

export enum CondFilter {
  AND = 'and',
  OR = 'or'
}

export enum CondOperator {
  EQUALS = '$eq',
  NOT_EQUALS = '$ne',
  GREATER_THAN = '$gt',
  GREATER_THAN_EQUALS = '$gte',
  LOWER_THAN = '$lt',
  LOWER_THAN_EQUALS = '$lte',
  CONTAINS = '$cont',
  EXCLUDES = '$excl',
  IN = '$in',
  NOT_IN = '$notin',
  IS_NULL = '$isnull',
  NOT_NULL = '$notnull',
  BETWEEN = '$between',
  IS_TRUE = '$istrue',
  IS_FALSE = '$isfalse',
  ARRAY_IN = '$arrayin',
  ARRAY_NOTIN = '$arraynotin',
  ARRAY_ANY = '$arrayany',
  DATE_BETWEEN = '$datebetween',
  DATE_EQUALS = '$dateeq',
  DATE_NOT_EQUALS = '$datene',
  DATE_GREATER_THAN = '$dategt',
  DATE_GREATER_THAN_EQUALS = '$dategte',
  DATE_LOWER_THAN = '$datelt',
  DATE_LOWER_THAN_EQUALS = '$datelte',
}

export const arrayOperators = [
  CondOperator.ARRAY_IN,
  CondOperator.ARRAY_NOTIN,
  CondOperator.ARRAY_ANY,
  CondOperator.IN,
  CondOperator.NOT_IN
]

export const dateRangeOperators = [
  CondOperator.DATE_BETWEEN
]

export const translatedCondName: { [key in CondFilter]: string } = {
  [CondFilter.AND]: i18n.global.t('filters.conditions.and'),
  [CondFilter.OR]: i18n.global.t('filters.conditions.or')
}

export const translatedOperatorName: { [key in CondOperator]: string } = {
  [CondOperator.EQUALS]: i18n.global.t('filters.operators.equals'),
  [CondOperator.NOT_EQUALS]: i18n.global.t('filters.operators.notEquals'),
  [CondOperator.CONTAINS]: i18n.global.t('filters.operators.contains'),
  [CondOperator.EXCLUDES]: i18n.global.t('filters.operators.excludes'),
  [CondOperator.IS_TRUE]: i18n.global.t('filters.operators.isTrue'),
  [CondOperator.IS_FALSE]: i18n.global.t('filters.operators.isFalse'),
  [CondOperator.GREATER_THAN]: i18n.global.t('filters.operators.greaterThan'),
  [CondOperator.GREATER_THAN_EQUALS]: i18n.global.t('filters.operators.greaterThanEquals'),
  [CondOperator.LOWER_THAN]: i18n.global.t('filters.operators.lowerThan'),
  [CondOperator.LOWER_THAN_EQUALS]: i18n.global.t('filters.operators.lowerThanEquals'),
  [CondOperator.IN]: i18n.global.t('filters.operators.in'),
  [CondOperator.NOT_IN]: i18n.global.t('filters.operators.notIn'),
  [CondOperator.IS_NULL]: i18n.global.t('filters.operators.isNull'),
  [CondOperator.NOT_NULL]: i18n.global.t('filters.operators.notNull'),
  [CondOperator.BETWEEN]: i18n.global.t('filters.operators.between'),
  [CondOperator.ARRAY_IN]: i18n.global.t('filters.operators.in'),
  [CondOperator.ARRAY_NOTIN]: i18n.global.t('filters.operators.notIn'),
  [CondOperator.ARRAY_ANY]: i18n.global.t('filters.operators.arrayAny'),
  [CondOperator.DATE_BETWEEN]: i18n.global.t('filters.operators.between'),
  [CondOperator.DATE_EQUALS]: i18n.global.t('filters.operators.equals'),
  [CondOperator.DATE_NOT_EQUALS]: i18n.global.t('filters.operators.notEquals'),
  [CondOperator.DATE_GREATER_THAN]: i18n.global.t('filters.operators.greaterThan'),
  [CondOperator.DATE_LOWER_THAN]: i18n.global.t('filters.operators.lowerThan'),
  [CondOperator.DATE_GREATER_THAN_EQUALS]: i18n.global.t('filters.operators.greaterThanEquals'),
  [CondOperator.DATE_LOWER_THAN_EQUALS]: i18n.global.t('filters.operators.lowerThanEquals')
}

export const operatorsByFieldType: { [key in FieldType]: { [key in CondOperator]?: ValueType[] } } = {
  [FieldType.UNKNOWN]: {},
  [FieldType.ROLES]: {},
  [FieldType.STRING]: {
    [CondOperator.EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'text'
      }
    ],
    [CondOperator.NOT_EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'text'
      }
    ],
    [CondOperator.CONTAINS]: [
      {
        componentType: 'input',
        fieldType: 'text'
      }
    ],
    [CondOperator.EXCLUDES]: [
      {
        componentType: 'input',
        fieldType: 'text'
      }
    ],
    [CondOperator.IN]: [
      {
        componentType: 'multivalues',
        fieldType: 'any'
      }
    ],
    [CondOperator.NOT_IN]: [
      {
        componentType: 'multivalues',
        fieldType: 'any'
      }
    ],
    [CondOperator.IS_NULL]: [
      {
        disable: true
      }
    ],
    [CondOperator.NOT_NULL]: [
      {
        disable: true
      }
    ]
  },
  [FieldType.STRING_LIST]: {
    [CondOperator.EQUALS]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.NOT_EQUALS]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.IN]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.NOT_IN]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.IS_NULL]: [
      {
        disable: true
      }
    ]
  },
  [FieldType.NUMBER]: {
    [CondOperator.EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.NOT_EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.GREATER_THAN]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.GREATER_THAN_EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.LOWER_THAN]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.LOWER_THAN_EQUALS]: [
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.BETWEEN]: [
      {
        componentType: 'input',
        fieldType: 'number'
      },
      {
        componentType: 'input',
        fieldType: 'number'
      }
    ],
    [CondOperator.IN]: [
      {
        componentType: 'multivalues',
        fieldType: 'any'
      }
    ],
    [CondOperator.NOT_IN]: [
      {
        componentType: 'multivalues',
        fieldType: 'any'
      }
    ],
    [CondOperator.IS_NULL]: [
      {
        disable: true
      }
    ],
    [CondOperator.NOT_NULL]: [
      {
        disable: true
      }
    ]
  },
  [FieldType.ARRAY]: {
    [CondOperator.ARRAY_IN]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.ARRAY_NOTIN]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ],
    [CondOperator.ARRAY_ANY]: [
      {
        componentType: 'multiselect',
        fieldType: 'any'
      }
    ]
  },
  [FieldType.BOOLEAN]: {
    [CondOperator.IS_TRUE]: [
      {
        disable: true
      }
    ],
    [CondOperator.IS_FALSE]: [
      {
        disable: true
      }
    ]
  },
  [FieldType.DATE]: {
    [CondOperator.DATE_BETWEEN]: [
      {
        componentType: 'date',
        fieldType: 'daterange'
      }
    ],
    [CondOperator.DATE_GREATER_THAN]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.DATE_GREATER_THAN_EQUALS]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.DATE_LOWER_THAN]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.DATE_LOWER_THAN_EQUALS]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.DATE_EQUALS]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.DATE_NOT_EQUALS]: [
      {
        componentType: 'date',
        fieldType: 'date'
      }
    ],
    [CondOperator.IS_NULL]: [
      {
        disable: true
      }
    ],
    [CondOperator.NOT_NULL]: [
      {
        disable: true
      }
    ]
  }
}

const requiredValidation = (v: any): boolean => v !== null && v !== undefined && v !== ''
const noParamValidation = (v: any): boolean => v === null || v === undefined
const requiredArrayValidation = (v: any): boolean => useJoi(Joi.array().min(1).required(), v) === undefined
const requiredDate = (v: any): boolean => useJoi(Joi.date().required(), v) === undefined

export const CondOperatorValidator: { [key in CondOperator]: (value?: any) => boolean } = {
  [CondOperator.EQUALS]: requiredValidation,
  [CondOperator.NOT_EQUALS]: requiredValidation,
  [CondOperator.GREATER_THAN]: requiredValidation,
  [CondOperator.GREATER_THAN_EQUALS]: requiredValidation,
  [CondOperator.LOWER_THAN]: requiredValidation,
  [CondOperator.LOWER_THAN_EQUALS]: requiredValidation,
  [CondOperator.CONTAINS]: requiredValidation,
  [CondOperator.EXCLUDES]: requiredValidation,
  [CondOperator.IN]: requiredArrayValidation,
  [CondOperator.NOT_IN]: requiredArrayValidation,
  [CondOperator.IS_NULL]: noParamValidation,
  [CondOperator.NOT_NULL]: noParamValidation,
  [CondOperator.BETWEEN]: v => useJoi(Joi.array().length(2).items(Joi.number()).required(), v) === undefined,
  [CondOperator.IS_TRUE]: noParamValidation,
  [CondOperator.IS_FALSE]: noParamValidation,
  [CondOperator.ARRAY_IN]: requiredArrayValidation,
  [CondOperator.ARRAY_NOTIN]: requiredArrayValidation,
  [CondOperator.ARRAY_ANY]: requiredArrayValidation,
  [CondOperator.DATE_BETWEEN]: v => useJoi(Joi.object({ start: Joi.date().required(), end: Joi.date().required() }).required(), v) === undefined,
  [CondOperator.DATE_EQUALS]: requiredDate,
  [CondOperator.DATE_NOT_EQUALS]: requiredDate,
  [CondOperator.DATE_GREATER_THAN]: requiredDate,
  [CondOperator.DATE_GREATER_THAN_EQUALS]: requiredDate,
  [CondOperator.DATE_LOWER_THAN]: requiredDate,
  [CondOperator.DATE_LOWER_THAN_EQUALS]: requiredDate
}

export function getOperatorsByFieldType (fieldType: FieldType): CondOperator[] {
  return Object.keys(operatorsByFieldType[fieldType]) as CondOperator[]
}

export function getFieldsByFieldTypeAndOperator (fieldType: FieldType, operator: CondOperator): ValueType[] | undefined {
  if (operatorsByFieldType[fieldType]) {
    if (operatorsByFieldType[fieldType][operator]) {
      return operatorsByFieldType[fieldType][operator]
    }
  }
}

/**
 * newDateForFiltering return date in format that allow comparaison for current CondOperator.
 *
 * Returns a new date with hours, minutes, seconds and milliseconds set to 0
 */
export function newDateForFiltering (date : Date|string) : Date {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}
