<template>
  <Tooltip
    :disabled="message == ''"
    trigger="hover"
    :reference-props="{
      title: '',
      class: tooltipClass
    }"
    :force-show="false"
  >
    <template #default="{ visible }">
      <component
        :is="mode === 'question' ? QuestionMarkSolid : InformationCircleIconSolid"
        :class="['w-4 h-4 transition-opacity absolute top-0 left-0', { 'opacity-0': !visible }]"
      />
      <component
        :is="mode === 'question' ? QuestionMarkOutline : InformationCircleIcon"
        :class="['w-4 h-4 transition-opacity', { 'opacity-0': visible }]"
      />
    </template>
    <template #title>
      <span
        class="text-sm font-normal whitespace-pre-line"
        :class="messageClass"
      >
        {{ message }}
      </span>
    </template>
  </Tooltip>
</template>

<script setup lang="ts">

import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { InformationCircleIcon as InformationCircleIconSolid } from '@heroicons/vue/24/solid'
import { useAttrs } from 'vue'

import QuestionMarkOutline from '../Icons/QuestionMarkOutline.vue'
import QuestionMarkSolid from '../Icons/QuestionMarkSolid.vue'

import Tooltip from './Tooltip.vue'

const attrs: Record<string, unknown> = useAttrs()
const tooltipClass = ['relative inline-flex', attrs.class]

withDefaults(
  defineProps<{
    message?: string
    messageClass?: string | object
    mode?: 'info' | 'question'
  }>(),
  {
    message: '',
    messageClass: '',
    mode: 'question'
  }
)
</script>
