import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import { v1 as uuidv1 } from 'uuid'
import { computed, isProxy, Ref, ref, toRaw, watch } from 'vue'

import { Filters } from '@/plugins/dashboard/source'
import { ColumnFilter, ColumnProperty, VIRTUAL_COLUMN_VALIDATION } from '@/plugins/filters'

import { BaseFilters, ClientFilters } from '../filters/filters'

import { Column, Datatable, DatatableColumns, DatatableItems, DatatableOptions, DatatableValidation, Row, ValidationStatus } from './datatable.d'
import { applyFiltersOnRows } from './filter'
import { applySearchOnRows } from './search'
import { getRowColumnValue } from './utils'
import { useValidation } from './validation'

function defaultOptions (opts?: DatatableOptions): DatatableOptions {
  const defaultOptions: DatatableOptions = {
    name: 'default',
    sorts: 'client',
    queryString: {
      sorts: true
    },
    selectedBy: 'id'
  }

  if (opts) {
    return { ...defaultOptions, ...opts }
  }

  return defaultOptions
}

export function useDatatable (initialItems: Ref<any[]>, columns: DatatableColumns, filters?: BaseFilters | Filters, opts?: DatatableOptions): Datatable {
  const options = defaultOptions(opts)

  const validation = useValidation(columns)

  const items = useDatatableItems(initialItems, columns.columns, validation, options)

  const processedItems = computed(() => {
    if (filters && filters instanceof ClientFilters) {
      // ClientFilters execute sorting and filtering on the client side
      // Sorting and filtering is applied on the rows automatically

      let sortedItems = orderBy(items.rows.value, filters.sorts.value.map((s) => {
        if (s.field === VIRTUAL_COLUMN_VALIDATION) {
          return (row: Row) => {
            switch (validation.getRowStatus(row)) {
              case ValidationStatus.ERROR:
                return 0
              case ValidationStatus.WARNING:
                return 1
              case ValidationStatus.INFO:
                return 2
              default:
                return 3
            }
          }
        }
        if (s.caseInsensitive === true) {
          return (v: any) => {
            if (typeof v.data[s.field] !== 'string') {
              console.warn('caseInsentive sorting in datatable column: field type value must be a string')
              return `data.${s.field}`
            }
            return v.data[s.field].toLowerCase()
          }
        }
        return `data.${s.field}`
      }), filters.sorts.value.map((s) => s.sortOrder === 'ASC' ? 'asc' : 'desc'))

      sortedItems = applyFiltersOnRows(sortedItems, filters)

      sortedItems = applySearchOnRows(sortedItems, filters)

      return sortedItems
    }

    return items.rows.value
  })

  return {
    rows: processedItems,
    options,
    validation,
    items
  }
}

function useDatatableItems (initialItems: Ref<any[] | undefined>, columns: Ref<Column[]>, validation: DatatableValidation, options: DatatableOptions): DatatableItems {
  const idMap = new WeakMap<any, string>()
  const rows = computed<Row[]>(() => {
    if (!initialItems.value) {
      return []
    }
    return initialItems.value.map((r, index) => {
      let obj = r

      if (isProxy(r)) {
        obj = toRaw(r)
      }

      const objectId = idMap.get(obj)

      if (objectId === undefined) {
        const id = uuidv1()

        idMap.set(obj, id)

        return { id, isSelected: ref(false), index: ref(index), data: r, isLoading: ref(false), isOpen: ref(false) }
      }

      return { id: objectId, isSelected: ref(false), index: ref(index), data: r, isLoading: ref(false), isOpen: ref(false) }
    })
  })
  watch(
    () => rows.value,
    v => {
      v.forEach(r => {
        const index = options.selectedRows?.value.findIndex((row: Row) => {
          if (Array.isArray(options.selectedBy)) {
            return options.selectedBy.every(key => row.data[key] === r.data[key])
          }
          return row.data[options.selectedBy!] === r.data[options.selectedBy!]
        })
        const selected = index !== undefined && index >= 0
        if (selected) {
          r.isSelected.value = true
          options.selectedRows!.value[index] = r
        }
      })
      validation.purge(v)
    }
  )
  const frozenRows = ref<Row[]>([])

  const addItem = (item: any): void => {
    initialItems.value?.push(item.value)

    const lastRow = rows.value[rows.value.length - 1]
    columns.value.forEach((column) => {
      if (column.validator) {
        validation.validCell(lastRow, column, getRowColumnValue(item.value, column))
      }
    })
  }

  const addFrozenItem = (item: any): void => {
    const itemIndex = rows.value.findIndex((r) => r.data === item)

    if (itemIndex >= 0) {
      frozenRows.value.push(rows.value[itemIndex])

      rows.value.splice(itemIndex, 0)
    } else {
      frozenRows.value.push({
        id: uuidv1(),
        isSelected: ref(false),
        index: ref(itemIndex),
        data: item,
        isLoading: ref(false),
        isOpen: ref(false)
      })

      columns.value.forEach((column) => {
        if (column.validator) {
          validation.validCell(item, column, getRowColumnValue(item, column))
        }
      })
    }
  }

  const clearItems = (selectedRows?: Row[]): void => {
    if (selectedRows) {
      const toRemove = selectedRows.map(sr => sr.id)
      initialItems.value = rows.value.filter(r => !toRemove.includes(r.id)).map(r => r.data)
    } else {
      initialItems.value = []
    }
  }

  return {
    rows,
    addItem,
    addFrozenItem,
    clearItems
  }
}

export function useDatatableColumns (initialColumns: Column[]): DatatableColumns {
  const columns = ref(cloneDeep(initialColumns).filter(c => {
    if (typeof c.condition === 'function') {
      return c.condition()
    }
    return true
  }).map((d) => ({ ...d, isVisible: typeof d.isVisible === 'boolean' ? d.isVisible : true })))

  const visibleColumns = initialColumns.map((c) => ({ field: c.field, isVisible: typeof c.isVisible === 'boolean' ? c.isVisible : true })) as ColumnProperty[]

  const filterableColumns: ColumnFilter[] = columns.value
    .filter((c) => c.filterable)
    .map((c) => ({ fieldKey: c.filterKey || c.field, fieldName: c.name, fieldType: c.filterableType, options: c.filterableOptions, dateOptions: { ...c.filterableDateOptions }, asyncOptions: c.filterableAsyncOptions })) as ColumnFilter[]

  const addColumn = (column: Column): void => {
    columns.value.push({ ...column, isVisible: typeof column.isVisible === 'boolean' ? column.isVisible : true })
  }

  const addColumnAt = (column: Column, index: number): void => {
    columns.value.splice(index, 0, { ...column, isVisible: typeof column.isVisible === 'boolean' ? column.isVisible : true })
  }

  const addColumnAfter = (column: Column, field: string): void => {
    const fieldIndex = columns.value.findIndex((c) => c.field === field)

    if (fieldIndex) {
      addColumnAt(column, fieldIndex + 1)
    }
  }

  const updateColumnsVisibility = (columns: Ref<Column[]>) => (columnsProperty: ColumnProperty[]) => {
    const order: Record<string, number> = {}
    columns.value.forEach(c => {
      const colIndex = columnsProperty.findIndex((co) => co.field === c.field)

      if (colIndex !== -1) {
        order[c.field] = colIndex
        c.isVisible = columnsProperty[colIndex].isVisible
        return
      }
      c.isVisible = false
    })
    columns.value.sort((a, b) => {
      const iA = order[a.field]
      const iB = order[b.field]
      if (iA === undefined || iB === undefined) {
        return 0
      }
      return iA - iB
    })
  }

  return {
    columns,
    initialColumns,
    visibleColumns,
    filterableColumns,

    addColumn,
    addColumnAt,
    addColumnAfter,
    updateColumnsVisibility
  }
}
