import { Adapter } from '@/models/adapter.base'

import { AdstxtLine as AdstxtLineBase } from './adstxtLine.base'
import { AdstxtLineRelationshipType, AdstxtLineType } from './enum/adstxt'
import { PublisherRelationship } from './enum/publisherRelationship'
import { SellerType } from './enum/sellerType'
import { Seat } from './seat'

export const EMPTY_ACCOUNT_ID = 'MISSING_ACCOUNT_ID'
export interface AdstxtLine extends AdstxtLineBase {
  seat?: Seat
  adapter?: Adapter
}

export interface AdstxtLineForm extends Omit<AdstxtLine, 'bidderId' | 'seatId'>{}
export interface AdstxtLinesOptinsForm {
  name: string
  adapterId: number
  isActive: boolean
  adstxtLines: AdstxtLineForm[]
}

export const computeAdagioLine = (publisherId: string, publisherRelationship?: PublisherRelationship, publisherSellerType?: SellerType, status?: boolean): AdstxtLine => {
  const relationship = (publisherSellerType === SellerType.PUBLISHER || publisherRelationship === PublisherRelationship.OWNED)
    ? AdstxtLineRelationshipType.DIRECT
    : AdstxtLineRelationshipType.RESELLER

  return {
    adstxtLineType: AdstxtLineType.ADAGIO,
    domainName: 'adagio.io',
    publisherAccountId: publisherId,
    relationship,
    status
  }
}

export const computeOwnerdomainAdstxtLine = (ownerdomain: string, status?: boolean): AdstxtLine => {
  return {
    adstxtLineType: AdstxtLineType.OWNERDOMAIN,
    domainName: ownerdomain,
    status
  }
}

export const computeOwnerAdstxtLine = (ownerdomain: string, publisherAccountId: string, certAuthId: string, status?: boolean): AdstxtLine => {
  return {
    adstxtLineType: AdstxtLineType.OWNER,
    domainName: ownerdomain,
    publisherAccountId,
    relationship: AdstxtLineRelationshipType.DIRECT,
    certAuthId,
    status
  }
}

export const computeManagerdomainAdstxtLine = (managerdomain: string, status?: boolean): AdstxtLine => {
  return {
    adstxtLineType: AdstxtLineType.MANAGERDOMAIN,
    domainName: managerdomain,
    status
  }
}

export const computeManagerAdstxtLine = (managerdomain: string, publisherAccountId: string, certAuthId: string, status?: boolean): AdstxtLine => {
  return {
    adstxtLineType: AdstxtLineType.MANAGER,
    domainName: managerdomain,
    publisherAccountId,
    relationship: AdstxtLineRelationshipType.RESELLER,
    certAuthId,
    status
  }
}
