import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { Ref, nextTick, ref, watch } from 'vue'
import { Router, useRouter } from 'vue-router'

import { PaginateWithoutRecords } from '@/types/paginate'

import { Context } from '@/plugins/context'

import { useContextStore } from '@/store/context.store'

import { View } from '@/models/view'

import { SetupCube } from '../cube/cube'
import { DatatableColumns, Row } from '../datatable/datatable.d'

import { QueryBuilder, QueryBuilderParams } from './builder'
import { CondFilter } from './operators'
import { QueryParser } from './parser'

import { ColumnProperty, Filter, Sort, SortOrder } from './index'

type UpdaterFnAsync = (queryParams: QueryBuilderParams) => Promise<any>

export class BaseFilters {
  sorts: Ref<Sort[]> = ref([])
  defaultColumns: ColumnProperty[] = []
  columns: Ref<ColumnProperty[]>
  search: Ref<string> = ref('')

  filters: Ref<Filter[]> = ref([])
  filtersUpdated: Ref<Filter[]>

  filtersCond: Ref<CondFilter | undefined>

  updateColumns: (columns: ColumnProperty[]) => void

  constructor (columns: DatatableColumns) {
    this.sorts = ref([])

    // Columns
    this.defaultColumns = columns.visibleColumns
    this.columns = ref(columns.visibleColumns)
    this.updateColumns = columns.updateColumnsVisibility(columns.columns)

    this.filtersUpdated = ref([])
    this.filtersCond = ref()
  }

  getClassName (): string {
    return this.constructor.name
  }

  setDefaultSort (...sorts: Sort[]): void {
    if (this.sorts.value.length === 0) {
      if (sorts.length === 0) {
        sorts.push({ field: 'id', sortOrder: SortOrder.ASC })
      }
      this.sorts.value.push(...sorts)
    }
  }
}

export class ClientFilters extends BaseFilters {
  searchSpecificComparators: Map<string, (search: string, value: any) => boolean> = new Map()

  // by default, the function isSearch is used to compare the search string with the value
  // if specific execution is needed for a field to compare with search value, you can add a specific comparator
  addSearchSpecificComparator<T> (field: string, comparator: (search: string, value: T) => boolean): void {
    this.searchSpecificComparators.set(field, comparator)
  }

  // isSearch will return if the value is search by the search string
  isSearch (field: string, value: any): boolean {
    const comparator = this.searchSpecificComparators.get(field)
    if (comparator) {
      return comparator(this.search.value.toLowerCase(), value)
    }
    return isSearch(this.search.value.toLowerCase(), value)
  }
}

function isSearch (search: string, value: any): boolean {
  if (Array.isArray(value)) {
    return value.some((v) => isSearch(search, v))
  } else if (typeof value === 'object') {
    return Object.keys(value).some((key) => {
      return key.toLowerCase().includes(search) || isSearch(search, value[key])
    })
  } else {
    // search is string but value might not be string e.g. number
    // convert value to string before comparing
    // true will be "true", 1 will be "1"...
    return String(value ?? '').toLowerCase().includes(search)
  }
}

/**
 * @deprecated use ServerFilters
 */
export function Filters (updater: UpdaterFnAsync, columns: DatatableColumns, tableName: string, pagination: Ref<PaginateWithoutRecords>): ServerFilters {
  return new ServerFilters(updater, columns, tableName, pagination)
}

export class ServerFilters extends BaseFilters {
  router: Router
  queryParser: QueryParser
  queryBuilder: QueryBuilder

  tableName: string

  properties: Ref<ColumnProperty[]>
  pagination: Ref<PaginateWithoutRecords>
  currentPage: Ref<number>
  selectedRows?: Ref<Row[]>
  currentView: Ref<View | null>
  defaultSorts?: Sort[]

  currentContext: Context | undefined

  debouncedUpdater: DebouncedFunc<() => void>

  isLoading: Ref<boolean>

  // Views
  canSaveView: Ref<boolean>

  constructor (updater: UpdaterFnAsync, columns: DatatableColumns, tableName: string, pagination: Ref<PaginateWithoutRecords>, selectedRows?: Ref<Row[]>, ignoreQueryURL: boolean = false, cube?: SetupCube) {
    super(columns)

    this.router = useRouter()

    this.tableName = tableName

    this.properties = ref([])
    this.pagination = pagination
    this.currentPage = ref(1)
    this.selectedRows = selectedRows
    this.currentView = ref(null)

    // isLoading indicates if we are fetching data from the server.
    // You should use this property to show a loading indicator in the Datatable component.
    this.isLoading = ref(false)

    this.canSaveView = ref(false)

    const contextStore = useContextStore()
    this.currentContext = contextStore.context || undefined

    this.queryBuilder = new QueryBuilder(ignoreQueryURL ? undefined : this.router)

    this.queryParser = new QueryParser(columns, this.router, {
      filters: this.filters,
      filtersCond: this.filtersCond,
      sorts: this.sorts,
      currentPage: this.currentPage,
      search: this.search,
      currentView: this.currentView
    }, ignoreQueryURL)

    this.updateQueryBuilder()

    // We use a debounced function to avoid multiple queries when we programmatically update
    // all `Filters` properties like we do during a View initialization.
    this.debouncedUpdater = debounce(() => {
      if (cube) {
        cube.applyFilters({
          filters: this.filters,
          filtersCond: this.filtersCond,
          sorts: this.sorts,
          currentPage: this.currentPage,
          search: this.search,
          currentView: this.currentView
        })
        cube.run()
          .finally(() => {
            this.isLoading.value = false
          })
      } else {
        updater(this.queryBuilder.queryObject.value)
          .finally(() => {
            this.isLoading.value = false
          })
      }
    }, 300)

    this.debouncedUpdater()

    this.initWatchers(columns)
  }

  initWatchers (columns: DatatableColumns): void {
    watch(
      columns.columns,
      () => {
        this.columns.value = columns.columns.value.map((c: any) => ({ field: c.field, isVisible: typeof c.isVisible === 'boolean' ? c.isVisible : true }))
      }, { deep: true }
    )

    watch(
      this.pagination,
      (oldPagination, newPagination) => {
        if (oldPagination.currentPage > 0) {
          if (newPagination.currentPage > 0) {
            if (newPagination.currentPage !== this.currentPage.value) {
              this.currentPage.value = newPagination.currentPage
            }
          }
        }
      }, { deep: true }
    )

    watch(
      [this.filters, this.sorts, this.currentPage, this.search],
      () => {
        this.updateQueryBuilder()
        this.debouncedUpdater()
      }, { deep: true }
    )

    watch(
      () => [this.search, this.filters],
      () => {
        this.resetPage()
      }, { deep: true }
    )

    watch(
      () => this.router.currentRoute.value.path,
      () => {
        const contextStore = useContextStore()

        if (contextStore?.context?.resourceId !== this.currentContext?.resourceId ||
          contextStore?.context?.resourceType !== this.currentContext?.resourceType) {
          this.currentContext = contextStore.context

          this.search.value = ''
        }
      }
    )

    watch(
      this.currentView,
      (newView, oldView) => {
        const isInitial = oldView === undefined
        if (newView === undefined && isInitial) {
          nextTick(() => {
            this.currentView.value = null
          })
          return
        }

        // Update the query String
        this.queryBuilder.setView(this.currentView.value, this.updateColumns)

        // Update all the ref's
        this.canSaveView.value = true

        if (this.currentView.value) {
          if (this.currentView.value.content) {
            const overrideCond = isInitial && this.filtersCond.value !== undefined
            if (oldView !== undefined) {
              this.sorts.value = []
              this.filters.value = []
            }
            if (this.currentView.value.content.sort) {
              this.queryParser.parseSorts(this.currentView.value.content.sort, isInitial)
            }

            if (this.currentView.value.content.filter) {
              this.queryParser.parseFilters(this.currentView.value.content.filter, overrideCond ? this.filtersCond.value! : CondFilter.AND, isInitial)
            }

            if (this.currentView.value.content.or) {
              this.queryParser.parseFilters(this.currentView.value.content.or, overrideCond ? this.filtersCond.value! : CondFilter.OR, isInitial)
            }

            if (this.filters.value.length) {
              this.filtersUpdated.value = this.filters.value
            }
          }
        } else if (oldView !== newView && oldView !== undefined) {
          this.canSaveView.value = false
          this.sorts.value = this.defaultSorts ? [...this.defaultSorts] : []
          this.filters.value = []
          this.filtersUpdated.value = []
          this.updateColumns(this.defaultColumns)
        }
      }, { immediate: true }
    )

    watch(
      () => this.router.currentRoute.value.query.view,
      (v) => {
        let viewId: number | undefined
        if (v) {
          try {
            const value = Array.isArray(v) ? v[0] : v
            if (value === null) {
              return
            }
            viewId = parseInt(value)
          } catch (e) {
            return
          }
        }
        if (!viewId) {
          return
        }
        this.queryParser.parseView(viewId)
      }
    )
  }

  resetPage (): void {
    this.currentPage.value = 1
    this.pagination.value.currentPage = 1
  }

  // updateQueryBuilder updates the params that will be used in the querystring.
  // This method is called before `debouncedUpdater()` and can be considered
  // as a "beforeFetch" hook. That why we set the isLoading property to true.
  updateQueryBuilder (): void {
    // isLoading will be set to false inside the `debouncedUpdater` function, after fetching is done.
    this.isLoading.value = true

    try {
      this.queryBuilder.setSorts(this.sorts.value)
      this.queryBuilder.setSearch(this.search.value)
      this.queryBuilder.setPagination(this.currentPage.value)

      if (this.filtersCond.value) {
        this.queryBuilder.setFilters(this.filters.value, this.filtersCond.value)
      }
    } catch (e) {
      // This try-catch should never be triggered, but we never know.
      this.isLoading.value = false
    }
  }

  setDefaultSort (...sorts: Sort[]): void {
    this.defaultSorts = sorts
    super.setDefaultSort(...sorts)
    if (this.sorts.value.length === 0) {
      this.queryBuilder.setSorts(this.sorts.value)
    }
  }

  setDefaultFilter (...filter: Filter[]): void {
    if (this.filters.value.length === 0) {
      this.filtersCond.value = CondFilter.AND
      this.filters.value.push(...filter)
      this.filtersUpdated.value = this.filters.value
      this.queryBuilder.setFilters(this.filters.value, this.filtersCond.value)
    }
  }

  get viewContent (): Record<string, any> {
    const result: { [key: string]: any } = {}

    if (this.queryBuilder.queryObject.value.filter) {
      result.filter = this.queryBuilder.queryObject.value.filter
    }

    if (this.queryBuilder.queryObject.value.or) {
      result.or = this.queryBuilder.queryObject.value.or
    }

    if (this.queryBuilder.queryObject.value.sort) {
      result.sort = this.queryBuilder.queryObject.value.sort
    }

    result.properties = this.columns.value

    return result
  }
}
