import { FormSelectOption } from '@/types/form'

export const multiselectTailwindClasses = {
  container: 'relative mx-auto w-full min-h-12.125 flex items-center justify-end box-border cursor-pointer border border-gray-border text-sm leading-snug outline-none rounded-md bg-white',
  containerDisabled: '!cursor-default !bg-gray-200',
  containerOpen: 'rounded-b-none',
  containerOpenTop: 'rounded-t-none',
  containerActive: 'ring-0 !border-primary-500 outline-none',
  wrapper: 'relative mx-auto min-h-12.125 w-full flex items-center justify-end box-border cursor-pointer outline-none',

  singleLabel: 'absolute top left-0 right-12 pl-5 pr-0 pt-[1.625rem] pb-1.5 pointer-events-none overflow-hidden overflow-ellipsis whitespace-nowrap bg-transparent text-base leading-[1.375rem] font-semibold text-text-primary',
  multipleLabel: 'absolute top left-0 right-12 pl-5 pr-0 pt-[1.625rem] pb-1.5 pointer-events-none overflow-hidden overflow-ellipsis whitespace-nowrap bg-transparent text-sm leading-[1.375rem] font-semibold text-text-primary',
  search: 'absolute w-full appearance-none inset-0 outline-none ring-0 focus:ring-0 box-border border-0 text-adagio text-sm font-normal pr-2.5 pl-5 pt-[1.375rem] pb-1 rounded-md focus:rounded-b-none bg-transparent',

  tags: 'flex-grow flex-shrink flex flex-wrap items-center pl-5 pr-0 pt-[1.625rem] pb-1.5 min-w-0',
  tag: 'text-slate-600 text-sm leading-none font-regular bg-gray-300 rounded flex items-center h-4 min-w-0 break-all pl-1.5 pr-1 mx-0.5 my-0.75',

  tagDisabled: 'pr-2 bg-gray-200',
  tagRemove: 'flex items-center justify-center p-0.5 ml-0.5 group',
  tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-2 h-2 group-hover:opacity-60',
  tagsSearchWrapper: 'inline-block relative flex-grow flex-shrink h-full',
  tagsSearch: 'absolute bg-transparent inset-0 border-0 outline-none appearance-none p-0 text-sm font-sans box-border w-full focus:outline-none focus:border-transparent focus:ring-0',
  tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
  placeholder: 'absolute left-2.5 inline-flex overflow-hidden overflow-ellipsis whitespace-nowrap pointer-events-none bg-transparent text-gray-600 text-sm',

  caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
  caretOpen: 'rotate-180 pointer-events-auto',

  clear: 'relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
  clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',

  spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
  dropdown: 'max-h-60 absolute -left-px -right-px bottom-px transform translate-y-full border border-t-0 border-primary-500 overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
  dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
  dropdownHidden: 'hidden',
  options: 'flex flex-col p-0 m-0 list-none w-max min-w-full text-text-primary',
  optionsTop: 'flex-col-reverse',
  group: 'p-0 m-0',
  groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
  groupLabelPointable: 'cursor-pointer',
  groupLabelPointed: 'bg-gray-300 text-gray-700',
  groupLabelSelected: 'bg-indigo-600 text-white',
  groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
  groupLabelSelectedPointed: 'bg-indigo-600 text-white opacity-90',
  groupLabelSelectedDisabled: 'text-indigo-100 bg-indigo-600 bg-opacity-50 !cursor-not-allowed',
  groupOptions: 'p-0 m-0',
  option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm text-text-primary leading-snug py-2 pl-5 pr-2 group',
  optionPointed: 'text-gray-800 bg-gray-100',
  optionSelected: '!bg-primary-200',
  optionDisabled: 'text-gray-300 !cursor-not-allowed',
  optionSelectedPointed: '!bg-primary-200 opacity-90',
  optionSelectedDisabled: 'text-gray-300 bg-primary-200 bg-opacity-50 !cursor-not-allowed',
  noOptions: 'py-2 px-5 text-gray-600 bg-white',
  noResults: 'py-2 px-5 text-gray-600 bg-white',
  fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
  spacer: 'h-6 py-px box-content',
  assist: 'sr-only'
}

export const multiselectTailwindClassesWithoutLabel = {
  ...multiselectTailwindClasses,
  tags: 'flex-grow flex-shrink flex flex-wrap items-center pl-2.5 py-1 min-w-0 -mx-0.75'
}

export const multiselectTailwindClassesCompact = Object.assign({}, multiselectTailwindClasses)
multiselectTailwindClassesCompact.container = multiselectTailwindClassesCompact.container.replaceAll('mt-1', '').replaceAll('min-h-12.125', '').replaceAll('text-base', 'text-sm')
multiselectTailwindClassesCompact.wrapper = multiselectTailwindClassesCompact.wrapper.replaceAll('min-h-12.125', 'min-h-7')
multiselectTailwindClassesCompact.singleLabel = multiselectTailwindClassesCompact.singleLabel.replaceAll('pl-5 pr-0 pt-[1.625rem] pb-1.5', 'pl-2.5').replaceAll('text-base', 'text-sm').replaceAll('font-semibold', '')
multiselectTailwindClassesCompact.multipleLabel = multiselectTailwindClassesCompact.multipleLabel.replaceAll('pl-5 pr-0 pt-[1.625rem] pb-1.5', 'pl-2.5').replaceAll('text-base', 'text-sm').replaceAll('font-semibold', '')
multiselectTailwindClassesCompact.tags = multiselectTailwindClassesCompact.tags.replaceAll('pl-5 pr-0 pt-[1.625rem] pb-1.5', 'pl-2.5 py-2').replaceAll('text-base', 'text-sm').replaceAll('font-semibold', '')
multiselectTailwindClassesCompact.spacer = multiselectTailwindClassesCompact.spacer.replaceAll('h-9', 'h-6')
multiselectTailwindClassesCompact.option = multiselectTailwindClassesCompact.option.replaceAll('py-2', 'py-0.5').replaceAll('pl-5', 'pl-2.5').replaceAll('text-base', 'text-sm')
multiselectTailwindClassesCompact.search = multiselectTailwindClassesCompact.search.replaceAll('pt-[1.375rem] pb-1', 'py-0').replaceAll('pl-5', 'pl-2.5').replaceAll('text-base', 'text-sm')
multiselectTailwindClassesCompact.spinner = multiselectTailwindClassesCompact.spinner.replaceAll('mr-3.5', 'mr-0')
multiselectTailwindClassesCompact.placeholder = multiselectTailwindClassesCompact.placeholder.replaceAll('pl-5', 'pl-2.5')

export type MultiselectValue = FormSelectOption | number | string | null | any[]

export const extractValue = (option: MultiselectValue): MultiselectValue => {
  if (!option) return null
  return typeof option === 'object' && !Array.isArray(option) ? option.id : option
}

export const extractLabel = (option: MultiselectValue): MultiselectValue => {
  if (!option) return null
  return typeof option === 'object' && !Array.isArray(option) ? option.name : option
}
