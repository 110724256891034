import { AxiosResponse } from 'axios'

import { rolesGroup } from '@/static/roles'

import { Paginate } from '@/types/paginate'

import { ServerErrors } from '@/plugins/form/serverErrors'
import { hasRole } from '@/plugins/permissions'

import { BulkUpdateForm } from '@/models/bulk'
import { InactiveReason } from '@/models/enum/inactiveReason'
import { Platform } from '@/models/enum/platform'
import { PublisherRelationship } from '@/models/enum/publisherRelationship'
import { Role } from '@/models/enum/roles'
import { SelectResponse } from '@/models/select'
import { BulkAppForm, BulkWebsiteForm, CreateWebsiteForm, EditWebsiteForm, ExistingApp, ExistingWebsite, Website, WebsiteShareAdstxtForm } from '@/models/website'
import { WhitelistsWithBidderLinesStatusWebsite } from '@/models/whitelist'

import { csvStringify } from '@/utils/csv'

import api from '@/api'

import { fetchForSelectFilters, FilterResponse } from './datatableFilters'

export async function fetchWebsites (queryParams?: Object): Promise<AxiosResponse<Paginate<Website[]>>> {
  return api.get<Paginate<Website[]>>('/websites', { requireContext: true, params: queryParams })
}

export async function fetchExistingWebsites (data: Array<Omit<ExistingWebsite, 'id'|'status'>>): Promise<AxiosResponse<ExistingWebsite[]>> {
  return api.post<ExistingWebsite[]>('/websites/existing', { websites: data }, { requireContext: true })
}

export async function fetchExistingApps (data: Array<Omit<ExistingApp, 'id'|'status'>>): Promise<AxiosResponse<ExistingApp[]>> {
  return api.post<ExistingApp[]>('/websites/existing/apps', { apps: data }, { requireContext: true })
}

export async function fetchWebsitesForSelect (queryParams?: Object): Promise<AxiosResponse<SelectResponse[]>> {
  return api.get<SelectResponse[]>('/websites?format=select', { requireContext: true, params: queryParams })
}

export async function fetchPublisherWebsitesForSelect (publisherId: number, queryParams?: Object): Promise<AxiosResponse<SelectResponse[]>> {
  return api.get<SelectResponse[]>(`/publishers/${publisherId}/websites?format=select`, { params: queryParams })
}

export async function fetchWebsite (id: number): Promise<AxiosResponse<Website>> {
  return api.get<Website>(`/websites/${id}`)
}

export async function fetchWebsitesForSelectFilters (field: string, query: object): Promise<AxiosResponse<FilterResponse[]>> {
  return fetchForSelectFilters('/websites', field, query)
}

// Important note: the data is modified in order to avoid to modify the API and ask clients to update their scripts based on it.
// Inactive reason set here as mockup doesn't include the field
export async function createWebsite (data: CreateWebsiteForm, roles: Role[]): Promise<AxiosResponse<Website>> {
  const dto: any = { ...data }

  if (!data.isActive) {
    dto.inactiveReason = InactiveReason.NOTCOMPLIANT
  }

  if (dto.inactiveReason === null || !hasRole(roles, rolesGroup.adagio)) {
    delete dto.inactiveReason
  }

  if (data.platform === Platform.WEB) {
    delete dto.bundle
  }

  if (data.publisherRelationship === PublisherRelationship.MANAGED && data.managerLine) {
    const ml = data.managerLine
    delete dto.managerLine
    dto.managerLines = [ml]
  }

  return api.post<Website>('/websites', dto, { requireContext: true })
}

export async function createWebsiteBulk (records: BulkWebsiteForm[]): Promise<AxiosResponse<void>> {
  const formData = new FormData()
  const blob = new Blob([csvStringify(records)], { type: 'text/csv;charset=utf-8;' })
  formData.append('websites', blob, 'websites.csv')

  return api.post('/websites/bulk', formData, {
    requireContext: true,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export async function createAppBulk (records: BulkAppForm[]): Promise<AxiosResponse<void>> {
  const formData = new FormData()

  // Set the generated name into the name and the platform
  const newRecords = records.map((r) => {
    r.platform = Platform.APP

    if (r.generatedName) {
      r.name = r.generatedName
    }

    return r
  })

  const blob = new Blob([csvStringify(newRecords)], { type: 'text/csv;charset=utf-8;' })
  formData.append('apps', blob, 'apps.csv')

  return api.post('/websites/bulk/apps', formData, {
    requireContext: true,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export async function deleteWebsite (id: number): Promise<AxiosResponse> {
  return api.delete(`/websites/${id}`)
}

export async function updateWebsite (id: number, data: Partial<EditWebsiteForm>): Promise<AxiosResponse<Website>> {
  return api.patch<Website>(`/websites/${id}`, data)
}

export async function updateWebsiteBulk (data: BulkUpdateForm): Promise<AxiosResponse<void>> {
  return api.patch('/websites/bulk', data, { requireContext: true })
}

export async function refreshAdstxt (publisherId: string, websiteId: string, data?: string): Promise<AxiosResponse<ServerErrors>> {
  const formData = new FormData()
  if (data) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    formData.append('ads.txt', blob, 'ads.txt')
  }

  return api.patch<ServerErrors>(`/publishers/${publisherId}/websites/${websiteId}/adstxt`, formData, {
    requireContext: false,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export async function refreshSellersjson (websiteId: string): Promise<AxiosResponse<ServerErrors>> {
  return api.put<ServerErrors>(`/websites/${websiteId}/sellersjson`, {}, {
    requireContext: false
  })
}

export async function shareAdstxts (websiteId: string, data: WebsiteShareAdstxtForm):Promise<AxiosResponse<ServerErrors>> {
  return api.post<ServerErrors>(`/websites/${websiteId}/share-adstxts`, data)
}

// fetchWhitelistsWithBidderLinesStatus returns a subset of the `whitelists` infos and the BidderLinesStatus (aggregation of adstxt_checks.status_level_agg).
export async function fetchWhitelistsWithBidderLinesStatus (websiteId: string, publisherId?: string, queryParams?: any): Promise<AxiosResponse<Paginate<WhitelistsWithBidderLinesStatusWebsite[]>>> {
  const route = publisherId ? `/publishers/${publisherId}/websites/${websiteId}/whitelists` : `/websites/${websiteId}/whitelists`
  return api.get<Paginate<WhitelistsWithBidderLinesStatusWebsite[]>>(route, { requireContext: !publisherId, params: queryParams })
}
