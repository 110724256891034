<template>
  <div>
    <div
      ref="collapseButtonRef"
      class="relative flex flex-row justify-between cursor-pointer select-none"
      :class="headerClass"
      @click="onHeaderClick"
    >
      <div class="pb-5 grow">
        <div class="flex flex-row items-center gap-1.5">
          <ChevronRightIcon
            class="w-5 h-5 transition-transform text-text-primary"
            :class="!actualValue ? 'transform rotate-90' : ''"
            aria-hidden="true"
          />
          <div class="font-semibold text-text-primary grow">
            <slot
              name="header"
              :collapse-button-ref="collapseButtonRef"
            />
          </div>
        </div>
        <slot
          name="subtitle"
        />
      </div>
    </div>
    <slot v-if="!actualValue" />
  </div>
</template>

<script lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ChevronRightIcon
  },
  props: {
    collapsed: {
      type: Boolean as PropType<Boolean|undefined>,
      default: () => undefined
    },
    openByDefault: {
      type: Boolean,
      default: () => false
    },
    headerClass: {
      type: [String, Array] as PropType<string|string[]>,
      default: () => []
    }
  },
  emits: ['update:collapsed'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const collapseButtonRef = ref<HTMLDivElement>() // TODO Change this to Button: Need Collapse.vue to be refactored with Accessibility care.
    const collapsed = ref(!props.openByDefault)
    const onHeaderClick = () => {
      if (props.collapsed !== undefined) {
        emit('update:collapsed', !props.collapsed)
      } else {
        collapsed.value = !collapsed.value
      }
    }
    const actualValue = computed(() => props.collapsed !== undefined ? props.collapsed : collapsed.value)

    return {
      actualValue,
      collapseButtonRef,
      onHeaderClick,
      t
    }
  }
})
</script>
