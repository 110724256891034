import { FormSelectOption } from '@/types/form'

import i18n from '@/plugins/i18n'

export enum OperatingSystem {
  ANDROID = 'android',
  IOS = 'ios',
  WINDOWS = 'windows',
  MAC = 'mac',
  LINUX = 'linux',
}

export const operatingSystems: Array<FormSelectOption<string>> = [
  { id: OperatingSystem.ANDROID, name: i18n.global.t('labels.android') },
  { id: OperatingSystem.IOS, name: i18n.global.t('labels.ios') },
  { id: OperatingSystem.WINDOWS, name: i18n.global.t('labels.windows') },
  { id: OperatingSystem.MAC, name: i18n.global.t('labels.mac') },
  { id: OperatingSystem.LINUX, name: i18n.global.t('labels.linux') }
]
