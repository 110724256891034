import { FormSelectOptionWithId } from '@/types/form'

import i18n from '@/plugins/i18n'

export enum Platform {
    WEB = 'web',
    APP = 'app',
}

export const platforms: Array<FormSelectOptionWithId<string>> = [
  { id: Platform.WEB, name: i18n.global.t('labels.website') },
  { id: Platform.APP, name: i18n.global.t('labels.app') }
]
