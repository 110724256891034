import capitalize from 'lodash/capitalize'
import numbro from 'numbro'
import { useI18n } from 'vue-i18n'

import { toUTC } from '@/utils/dates'

import { TimeGranularityName } from './dimensions'

/**
 * Transforms a raw value into a formatted string used for display in
 * all kinds of widgets (including charts, tooltips, etc)
 */
export type MetricFormatter = (value: any, ...args: any) => string

function validNumber (n: any): boolean {
  return !Number.isNaN(n) && typeof n === 'number'
}

export const METRIC_FORMATTERS: Record<string, MetricFormatter> = {
  INTEGER: v => !validNumber(v) ? '-' : numbro(v).format({ thousandSeparated: true, mantissa: 0 }),
  COMPACT_INTEGER: v => !validNumber(v) ? '-' : numbro(v).format({ average: true, lowPrecision: false, mantissa: 2 }).toUpperCase(),
  FLOAT: v => !validNumber(v) ? '-' : numbro(v).format({ thousandSeparated: true, mantissa: 2 }),
  COMPACT_FLOAT: v => !validNumber(v) ? '-' : numbro(v).format({ average: true, lowPrecision: false, mantissa: 2 }).toUpperCase(),
  RAW: v => v,
  DATE: (v, granularity: TimeGranularityName) => {
    if (Number.isNaN(v) || v === undefined) {
      return '-'
    }
    const date = v instanceof Date ? v : new Date(v + 'Z')
    return isNaN(date.getTime()) ? '-' : toUTC(date, granularity === 'hour' ? 'yyyy-MM-dd Ka' : 'yyyy-MM-dd')
  }, // TODO date format in french locale
  PERCENT: v => !validNumber(v) ? '-' : (v === Infinity ? '+∞' : numbro(v).format({ output: 'percent', spaceSeparated: true, thousandSeparated: true, mantissa: 2 })),
  COMPACT_PERCENT: v => !validNumber(v) ? '-' : (v === Infinity ? '+∞' : numbro(v).format({ output: 'percent', spaceSeparated: true, thousandSeparated: true, mantissa: 0 })),
  SIGNED_PERCENT: v => !validNumber(v) ? '-' : (v === Infinity ? '+∞' : numbro(v).format({ output: 'percent', spaceSeparated: true, thousandSeparated: true, mantissa: 0, forceSign: true })),
  CURRENCY_DOLLAR: v => !validNumber(v) ? '-' : ('$ ' + numbro(v).format({ thousandSeparated: true, mantissa: 2 })),
  COMPACT_CURRENCY: v => !validNumber(v) ? '-' : numbro(v).format({ average: true, lowPrecision: false, mantissa: 2, optionalMantissa: true }),
  COMPACT_CURRENCY_DOLLAR: v => !validNumber(v) ? '-' : ('$ ' + numbro(v).format({ average: true, lowPrecision: false, mantissa: 2, optionalMantissa: true }).toUpperCase()),
  CURRENCY_EUR: v => !validNumber(v) ? '-' : (numbro(v).format({ thousandSeparated: true, mantissa: 2 }) + ' €'),
  COMPACT_CURRENCY_EUR: v => !validNumber(v) ? '-' : (numbro(v).format({ average: true, lowPrecision: false, mantissa: 2 }).toUpperCase() + ' €'),
  WEBSITE_STATUS: v => {
    if (typeof v !== 'string') {
      v = v?.toString() || ''
    }
    if (v.length > 4 && v.slice(0, 4).endsWith('_')) {
      v = v.slice(4)
    }
    return v ? capitalize(v) : '-'
  },
  TRANSLATION: (v) => {
    if (typeof v !== 'string') {
      v = v?.toString() || ''
    }

    return useI18n().t('metrics.' + v, 0, { missingWarn: false, fallbackWarn: false, default: v })
  }
}
